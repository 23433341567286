import React, { Component } from "react";
import Header from "../base/Header";
import Footer from "../base/Footer";
import Modal from "../base/Modal";
import { Link } from "react-router-dom";
import TopBarHeader from "../base/TopBarHeader";
import axiosInstance from "../api/axiosInstance";

export default class FAQ extends Component {
  constructor() {
    super();
    this.state = {
      order: [],
      wishlist: [],
      order_subtotal: 0,
    };
    this.handleWishlishChange = this.handleWishlishChange.bind(this);
    this.handleCartChange = this.handleCartChange.bind(this);
  }

  async componentDidMount() {
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async handleWishlishChange() {
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async handleCartChange() {
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopBarHeader />
        <Header order={this.state.order} wishlist={this.state.wishlist} />

        <div>
          {/* <!-- ======================= Top Breadcrubms ======================== --> */}
          <div className="gray py-3">
            <div className="container">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Pages</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        FAQ's
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- ======================= FAQ's Detail ======================== --> */}
          <section className="middle">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="sec_title position-relative text-center">
                    <h2 className="off_title">FAQ's Section</h2>
                    <h3 className="ft-bold pt-3">Frequently Asked Questions</h3>
                  </div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between">
                <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
                  <div className="d-block position-relative border rounded py-3 px-3 mb-4">
                    <h4 className="ft-medium">Orders:</h4>
                    <div id="accordion" className="accordion">
                      <div className="card">
                        <div className="card-header" id="h1">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#ord1"
                              aria-expanded="true"
                              aria-controls="ord1"
                            >
                              Can I track my order item?
                            </button>
                          </h5>
                        </div>

                        <div
                          id="ord1"
                          className="collapse show"
                          aria-labelledby="h1"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            Yes, I can track order item from my-order list.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="h2">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#ord2"
                              aria-expanded="false"
                              aria-controls="ord2"
                            >
                              Who pays for return postage?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="ord2"
                          className="collapse"
                          aria-labelledby="h2"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            Customer who buys the product pays for return postage.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="h3">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#ord3"
                              aria-expanded="false"
                              aria-controls="ord3"
                            >
                              How do I apply a promotional code?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="ord3"
                          className="collapse"
                          aria-labelledby="h3"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            You can apply promotion code from your shopping cart.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-block position-relative border rounded py-3 px-3 mb-4">
                    <h4 className="ft-medium">Shipping & Returns:</h4>
                    <div id="accordion1" className="accordion">
                      <div className="card">
                        <div className="card-header" id="h4">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#ord4"
                              aria-expanded="true"
                              aria-controls="ord4"
                            >
                              How long does it take for home delivery?
                            </button>
                          </h5>
                        </div>

                        <div
                          id="ord4"
                          className="collapse show"
                          aria-labelledby="h4"
                          data-parent="#accordion1"
                        >
                          <div className="card-body">
                            We usually deliver orders within 24 hours but in some rear cases it can take upto 72 hours.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="h5">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#ord5"
                              aria-expanded="false"
                              aria-controls="ord5"
                            >
                              What courier do you use for deliveries?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="ord5"
                          className="collapse"
                          aria-labelledby="h5"
                          data-parent="#accordion1"
                        >
                          <div className="card-body">
                            We deliver the product using our in-house delevery model.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="h6">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#ord6"
                              aria-expanded="false"
                              aria-controls="ord6"
                            >
                              Can I collect from a local store?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="ord6"
                          className="collapse"
                          aria-labelledby="h6"
                          data-parent="#accordion1"
                        >
                          <div className="card-body">
                            Yes, You can collect from our local store located in Bharatpur-10, Chitwan.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-block position-relative border rounded py-3 px-3">
                    <h4 className="ft-medium">Payment:</h4>
                    <div id="accordion2" className="accordion">
                      <div className="card">
                        <div className="card-header" id="h7">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#ord7"
                              aria-expanded="true"
                              aria-controls="ord7"
                            >
                              Do you offer a VAT discount to non EU customers?
                            </button>
                          </h5>
                        </div>

                        <div
                          id="ord7"
                          className="collapse show"
                          aria-labelledby="h7"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            No, we don't offer VAT discount to non EU customers.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="h8">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#ord8"
                              aria-expanded="false"
                              aria-controls="ord8"
                            >
                              Why have you not refunded the original delivery
                              charge?
                            </button>
                          </h5>
                        </div>
                        <div
                          id="ord8"
                          className="collapse"
                          aria-labelledby="h8"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            We only refund the order price after delivery which doesn't include delivery charge.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
        <Modal order={this.state.order} order_subtotal={this.state.order_subtotal} wishlist={this.state.wishlist} onWishlishChange={this.handleWishlishChange} onCartChange={this.handleCartChange} />
      </React.Fragment>
    );
  }
}
