import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Shop from "./components/shop/Shop";
import Blogs from "./components/blog/Blogs";
import BlogDetail from "./components/blog/BlogDetail";
import About from "./components/others/About";
import Contact from "./components/others/Contact";
import Privacy from "./components/others/Privacy";
import FAQ from "./components/others/FAQ";
import ProductDetail from "./components/product/ProductDetail";
import NotFound404 from "./components/others/NotFound404";
import ProductType from "./components/category/ProductType";
import Category from "./components/category/Category";
import ShopingCart from "./components/support/ShopingCart";
import Wishlist from "./components/dashboard/Wishlist";
import Checkout from "./components/support/Checkout";
import OrderCompleted from "./components/support/OrderCompleted";
import MyOrders from "./components/dashboard/MyOrders";
import Addresses from "./components/dashboard/Addresses";
import ProfileInfo from "./components/dashboard/ProfileInfo";
import AddAddress from "./components/dashboard/AddAddress";
import EditAddress from "./components/dashboard/EditAddress";
import SearchProduct from "./components/category/SearchProduct";
// import PaymentMethods from "./components/dashboard/PaymentMethods";
// import AddPaymentCard from "./components/dashboard/AddPaymentCard";
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";

export default function App() {
  return (
    <>
      <Routes>
        {/* Main Pages */}
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/blogs" element={<Blogs />} />

        {/* Authentication */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Dashboard Pages */}
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/addresses" element={<Addresses />} />
        <Route path="/addresses/add-address" element={<AddAddress />} />
        <Route path="/addresses/edit-address/:id" element={<EditAddress />} />
        <Route path="/profile" element={<ProfileInfo />} />
        {/* <Route exact path="/payment-methods">
            <PaymentMethods />
          </Route>
          <Route exact path="/payment-methods/add-card">
            <AddPaymentCard />
          </Route> */}

        {/* Support Pages */}
        <Route path="/shoping-cart" element={<ShopingCart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/complete-order" element={<OrderCompleted />} />

        {/* Other Pages */}
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<FAQ />} />

        {/* Slug Pages */}
        <Route path="/shop/:slug" element={<ProductType />} />
        <Route path="/category/:slug" element={<Category />} />
        <Route path="/product/:slug" element={<ProductDetail />} />
        <Route path="/blogs/:slug" element={<BlogDetail />} />
        <Route path="/search/:query" element={<SearchProduct />} />
        <Route path="/*" element={<NotFound404 />} />
      </Routes>
    </>
  );
}
