import React, { Component } from "react";
import Header from '../base/Header';
import Footer from '../base/Footer';
import Modal from '../base/Modal';
import BottomFeatures from "../base/BottomFeatures";
import FilterProduct from "./components/FilterProduct";
import ProductList from "./components/ProductList";
import TopStyle from "./components/TopStyle";
import axiosInstance from "../api/axiosInstance";
import TopBarHeader from "../base/TopBarHeader";
import { Link } from "react-router-dom";

export default class Shop extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      order: [],
      wishlist: [],
      order_subtotal: 0,
      categories: [],
      price_range: {},
      sizes: [],
      brands: [],
      colors: [],
      filters: {
        "product_type": "",
        "categories": [],
        "subcategories": [],
        "price_range": {},
        "sizes": [],
        "brands": [],
        "colors": [],
        "sort": "",
      },
    };
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleWishlishChange = this.handleWishlishChange.bind(this);
    this.handleCartChange = this.handleCartChange.bind(this);
  }

  async componentDidMount() {
    let res = await axiosInstance.get(`/shop/`);
    let data = await res.data;
    this.setState({
      items: data.items,
      categories: data.categories,
      price_range: data.price_range,
      sizes: data.sizes,
      brands: data.brands,
      colors: data.colors,
    });
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async handleChangeFilter(filter) {
    if (filter['categories']){
      await this.setState({
        filters: {
          "search_query": "",
          "product_type": this.state.filters['product_type'],
          "categories": [...this.state.filters['categories'], filter['categories']],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['subcategories']){
      await this.setState({
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": [...this.state.filters['subcategories'], filter['subcategories']],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['price_range']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": filter['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['sizes']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": [...this.state.filters['sizes'], filter['sizes']],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['brands']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": [...this.state.filters['brands'], filter['brands']],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['colors']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": [...this.state.filters['colors'], filter['colors']],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['sort']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": filter['sort'],
        }
      });
    } else{

    }
    let res1 = await axiosInstance.post(`/filter-product/`, {"filters": this.state.filters});
    let data1 = await res1.data;
    this.setState({
      items: data1.items,
    });
  }
  
  async handleWishlishChange() {
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async handleCartChange() {
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopBarHeader />
        <Header order={this.state.order} wishlist={this.state.wishlist} />

        <div>
          {/* <!-- ======================= Top Style ======================== --> */}
          <TopStyle />
          
          {/* <!-- ======================= Filter Wrap Style 1 ======================== --> */}
          <section className="py-3 br-bottom br-top">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Shop</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>

          <section className="middle">
            <div className="container">
              <div className="row">
                {/* <!-- ======================= Filter Product ======================== --> */}
                <FilterProduct categories={this.state.categories} price_range={this.state.price_range} sizes={this.state.sizes} brands={this.state.brands} colors={this.state.colors} filters={this.state.filters} onFilterChange={this.handleChangeFilter} />

                {/* <!-- ======================= Product List ======================== --> */}
                <ProductList items={this.state.items} onFilterChange={this.handleChangeFilter} onWishlishChange={this.handleWishlishChange} />
              </div>
            </div>
          </section>

        </div>

        <BottomFeatures />
        <Footer />
        <Modal order={this.state.order} order_subtotal={this.state.order_subtotal} wishlist={this.state.wishlist} onWishlishChange={this.handleWishlishChange} onCartChange={this.handleCartChange} />
      </React.Fragment>
    );
  }
}
