import React, { Component } from "react";
import Header from "../base/Header";
import Footer from "../base/Footer";
import Modal from "../base/Modal";
import BottomFeatures from "../base/BottomFeatures";
import FilterProductCategory from "./components/FilterProductCategory";
import ProductList from "./components/ProductList";
import { Link } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import TopBarHeader from "../base/TopBarHeader";
import { useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <Category {...props} params={params} />;
  }
  return ComponentWithRouter;
}

class Category extends Component {
  constructor() {
    super();
    this.state = {
      category_slug: "",
      items: [],
      order: [],
      wishlist: [],
      order_subtotal: 0,
      subcategories: [],
      price_range: {},
      sizes: [],
      brands: [],
      colors: [],
      filters: {
        "search_query": "",
        "product_type": "",
        "categories": [],
        "subcategories": [],
        "price_range": {},
        "sizes": [],
        "brands": [],
        "colors": [],
        "sort": "",
      },
    };
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleWishlishChange = this.handleWishlishChange.bind(this);
    this.handleCartChange = this.handleCartChange.bind(this);
    this.componentLogic = this.componentLogic.bind(this);
  }

  async componentLogic() {
    await this.setState({
      slug: this.props.params.slug,
      filters: {
        "search_query": "",
        "product_type": "",
        "categories": [this.props.params.slug],
        "subcategories": this.state.filters['subcategories'],
        "price_range": this.state.filters['price_range'],
        "sizes": this.state.filters['sizes'],
        "brands": this.state.filters['brands'],
        "colors": this.state.filters['colors'],
        "sort": this.state.filters['sort'],
      }
    });
    let res = await axiosInstance.get(`/category/${this.state.slug}/`);
    let data = await res.data;
    this.setState({
      items: data.items,
      subcategories: data.subcategories,
      price_range: data.price_range,
      sizes: data.sizes,
      brands: data.brands,
      colors: data.colors,
    });
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async componentDidMount() {
    this.componentLogic();
  }
  
  async componentDidUpdate(prevProps) {
    if (
      this.props.params.slug !==
      prevProps.params.slug
    ) {
      window.scrollTo(0, 0);
      this.componentLogic();
    }
  }

  async handleChangeFilter(filter) {
    if (filter['subcategories']){
      await this.setState({
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": [...this.state.filters['subcategories'], filter['subcategories']],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['price_range']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": filter['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['sizes']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": [...this.state.filters['sizes'], filter['sizes']],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['brands']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": [...this.state.filters['brands'], filter['brands']],
          "colors": this.state.filters['colors'],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['colors']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": [...this.state.filters['colors'], filter['colors']],
          "sort": this.state.filters['sort'],
        }
      });
    } else if (filter['sort']){
      await this.setState({ 
        filters: {
          "search_query": "",
          "product_type": "",
          "categories": this.state.filters['categories'],
          "subcategories": this.state.filters['subcategories'],
          "price_range": this.state.filters['price_range'],
          "sizes": this.state.filters['sizes'],
          "brands": this.state.filters['brands'],
          "colors": this.state.filters['colors'],
          "sort": filter['sort'],
        }
      });
    } else{

    }
    let res1 = await axiosInstance.post(`/filter-product/`, {"filters": this.state.filters});
    let data1 = await res1.data;
    this.setState({
      items: data1.items,
    });
  }

  async handleWishlishChange() {
    let res2 = await axiosInstance.get(`/wishlist/`);
    let data2 = await res2.data;
    this.setState({
      wishlist: data2.wishlist,
    });
  }

  async handleCartChange() {
    let res1 = await axiosInstance.get(`/order-cart/`);
    let data1 = await res1.data;
    this.setState({
      order: data1.order,
      order_subtotal: data1.subtotal,
    });
  }

  render() {
    const capitalizeFirst = slug => {
      return slug?.charAt(0).toUpperCase() + slug?.slice(1);
    };
    let items = this.state.items;
    let slug = this.state.slug;
    return (
      <React.Fragment>
        <TopBarHeader />
        <Header order={this.state.order} wishlist={this.state.wishlist} />

        <div>
          {/* <!-- ======================= Shop Style 1 ======================== --> */}
          <section
            className="bg-cover"
            style={{ background: "url(/assets/img/banner-2.png) no-repeat" }}
          >
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="text-left py-5 mt-3 mb-3">
                    <h1 className="ft-medium mb-3">Shop</h1>
                    <ul className="shop_categories_list m-0 p-0">
                      <li>
                        <Link to="/shop/mens">Men</Link>
                      </li>
                      <li>
                        <Link to="/shop/kids">Kids</Link>
                      </li>
                      <li>
                        <Link to="/shop/womens">Women</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- ======================= Filter Wrap Style 1 ======================== --> */}
          <section className="py-3 br-bottom br-top">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Category</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {capitalizeFirst(slug)}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- ======================= All Products ======================== --> */}
          <section className="middle">
            <div className="container">
              <div className="row">
                {/* <!-- ======================= Filter Product ======================== --> */}
                <FilterProductCategory subcategories={this.state.subcategories} price_range={this.state.price_range} sizes={this.state.sizes} brands={this.state.brands} colors={this.state.colors} filters={this.state.filters} onFilterChange={this.handleChangeFilter} />

                {/* <!-- ======================= Product List ======================== --> */}
                <ProductList items={items} onFilterChange={this.handleChangeFilter} />
              </div>
            </div>
          </section>
        </div>

        <BottomFeatures />
        <Footer />
        <Modal order={this.state.order} order_subtotal={this.state.order_subtotal} wishlist={this.state.wishlist} onWishlishChange={this.handleWishlishChange} onCartChange={this.handleCartChange} />
      </React.Fragment>
    );
  }
}

const CategoryPage = withRouter(Category);

export default CategoryPage;